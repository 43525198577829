@import '@styles/index.scss';

.menu {
  & :global(.MuiList-root) {
    padding: $indent-xxxs 0;
  }

  .menuItem {
    padding: $indent-xxxs $indent-xs;
  }

  .menuItemIconWrapper {
    &:global(.MuiListItemIcon-root) {
      min-width: 20px;
      margin-right: $indent-xxs;
    }
  }

  .menuItemIcon {
    width: 20px;
    height: 20px;
    color: $black;
  }

  .menuItemTitle {
    text-transform: capitalize;
  }
}

.type {
  &:global(.MuiChip-root) {
    min-width: 64px;
    color: $black;
  }
}

.date {
  white-space: nowrap;
}

.actions {
  min-height: 30px;
  margin: $indent-xxs 0;
}

.iconButton {
  &:global(.MuiIconButton-root) {
    padding: 4px;
    border: 0;
    color: $black;

    &:hover {
      border: 0;
      color: $red;
    }
  }
}

.paper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
}

.loadMoreBtn {
  &:global(.MuiButton-root) {
    align-self: center;
  }
}
