@import '@styles/index.scss';

.paper {
  position: relative;
  overflow: hidden;
  padding: 20px;

  .loadingIndicator {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
  }
}

.form {
  max-width: 230px;
}
