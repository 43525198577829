@import './screen-sizes.module';

@mixin scrollbar($track-color, $bg-color) {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: $track-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $bg-color;
    border-radius: 4px;
  }

  scrollbar-width: thin;
  scrollbar-color: $bg-color $track-color;
}

@mixin breakpoint($point) {
  @if $point == tablet {
    @media (min-width: $md) {
      @content;
    }
  } @else if $point == desktop {
    @media (min-width: $lg) {
      @content;
    }
  } @else if $point == desktopLarge {
    @media (min-width: $xl) {
      @content;
    }
  } @else if $point == desktopExtraLarge {
    @media (min-width: $xxl) {
      @content;
    }
  }
}
