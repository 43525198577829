@use '@styles/colors.module.scss';
@use '@styles/borders.module.scss';
@use '@styles/indents.module.scss';
@use '@styles/typography.module.scss';
@use '@styles/font-weights.module.scss';

.root {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
}

.input {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 44px 0 indents.$indent-xs;
  width: 100%;
  height: 40px;

  border: 1px solid colors.$grey-light;
  border-radius: borders.$radius-s;

  font-size: typography.$bodyFontSize;
  line-height: typography.$h2LineHeight;
  font-weight: font-weights.$regular;
  &.menuSelect {
    background: colors.$white;
    border: 2px solid colors.$red;
    padding: 0 indents.$indent-s;
    color: colors.$red;
    &:hover {
      color: colors.$black;
      border-color: colors.$black;
    }
    &:hover .placeholder {
      color: colors.$black;
      border-color: colors.$black;
    }
  }
  &.menuSelect .placeholder {
    color: colors.$red;
  }

  cursor: pointer;

  transition: border-color 0.124s ease-in;

  &:hover {
    border-color: colors.$dark;
  }

  &.focus {
    border-color: colors.$black;
    &.menuSelect {
      border-color: colors.$red;
    }
  }

  &.errorInput {
    border-color: colors.$error;
  }

  &.disabledInput {
    background-color: colors.$background;
    color: colors.$grey;
    cursor: default;

    &:hover {
      border-color: colors.$grey-light;
    }

    &.menuSelect {
      border-color: colors.$grey-light;
      color: colors.$grey;
      &:hover {
        border-color: colors.$grey-light;
        color: colors.$grey;
      }
      &:hover .placeholder {
        border-color: colors.$grey-light;
        color: colors.$grey;
      }
    }
    &.menuSelect .placeholder {
      color: colors.$grey;
    }
    .arrow {
      transition: color 0.124s ease-in;
      color: colors.$grey;
    }
  }
}

.placeholder {
  color: colors.$grey;
  white-space: nowrap;
  user-select: none;
  &.menuSelect {
    font-size: typography.$bodyFontSize;
    font-weight: font-weights.$semi-bold;
    line-height: typography.$bodyLineHeight;
  }
}

.arrow {
  position: absolute;
  height: 24px;
  width: 24px;
  right: 12px;

  transition: transform 0.12s ease-in !important;
  color: colors.$red;

  &.open {
    transform: rotate(180deg);
  }
}

.emptyList {
  display: flex;
  align-items: center;
  color: colors.$grey;
  height: 36px;
  padding: 0 indents.$indent-xs;
  font-size: typography.$bodyFontSize;
  font-weight: font-weights.$regular;
  gap: 10px;
}

.listInput {
  padding: 0 indents.$indent-xs indents.$indent-xxxs;
}

.disabledTag {
  background-color: colors.$grey !important;
}

.removeIcon {
  position: absolute;
  right: 20px;

  &:hover {
    transition: 0.3s;
    color: colors.$red;
  }
}
