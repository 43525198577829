.root {
  position: relative;
  overflow: hidden;
}

.response {
  div,
  input {
    height: 64px;
  }
}

.loadingIndicator {
  &:global(.MuiLinearProgress-root) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
