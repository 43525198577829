@use '@styles/colors.module.scss';
@use '@styles/borders.module.scss';
@use '@styles/indents.module.scss';
@use '@styles/font-weights.module.scss';

.root {
  .field,
  .submit {
    width: 278px;
  }

  .fieldLabel {
    flex: 0 0 32px;
    margin-right: indents.$indent-xs;
  }

  .keyHole {
    margin-left: 4px;
  }

  .submit {
    margin-left: 44px;
  }

  .showPassButton {
    display: block;
    margin-top: 4px;
    margin-left: 56px;
    color: colors.$red;
    overflow: hidden;

    &:hover {
      text-decoration: underline;
    }

    &:active {
      text-decoration: none;
    }

    transition-property: height, margin-top, opacity;
    transition: 0.12s ease-in;
    opacity: 1;

    &.hide {
      opacity: 0;
      height: 0;
      margin-top: 0;
    }
  }

  .httpError {
    overflow: hidden;
    margin-top: indents.$indent-xs;
    margin-bottom: -(indents.$indent-xxs);
    margin-left: 44px;
    padding: indents.$indent-xxs indents.$indent-xs;
    width: 278px;
    border: 1px solid colors.$error;
    border-radius: borders.$radius-s;
    background-color: colors.$light-red;

    .httpErrorTitle {
      margin-right: indents.$indent-xxs;
      color: colors.$error;
      font-weight: font-weights.$semi-bold;
    }

    transition-property: transform, height, padding, opacity, margin;
    transition: 0.12s ease-in;
    transform-origin: center top;
    opacity: 1;

    &.disabled {
      opacity: 0;
      transform: scaleY(0);
      padding: 0;
      margin: 0;
      height: 0 !important;
    }
  }
}

.loadingIndicator {
  &:global(.MuiLinearProgress-root) {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
  }
}
