// Main palette
$black: #000000;
$white: #ffffff;
$red: #f52d30;
$blue: #3c50e0;

// Grey palette
$dark: #242424;
$grey: #878787;
$grey-light: #e0e0e0;
$grey25: #ececec;
$almost-white: #f1f1f1;
$background: #f5f5f5;
$backdrop: rgba(0, 0, 0, 0.3);

// Purple
$purple50: #c6b9fe;

// Red
$red50: #f99797;

// System
$error: #fe5f55;
$success: #22ce7b;
$blue: #3c50e0;
$light-red: #fee7e7;

:export {
  black: $black;
  white: $white;
  red: $red;
  blue: $blue;

  dark: $dark;
  grey: $grey;
  greyLight: $grey-light;
  almostWhite: $almost-white;
  background: $background;
  backdrop: $backdrop;

  error: $error;
  success: $success;
  lightRed: $light-red;
  blue: $blue;
}
