@import '@styles/colors.module.scss';

.spinnerWrapper {
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.spinner {
  & svg {
    color: $red;
  }
}
