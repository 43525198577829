@use '@styles/colors.module.scss';

.root {
  border: 1px solid colors.$grey-light;

  .accordion {
    & :global(.MuiAccordionSummary-expandIconWrapper.Mui-expanded)  {
      transform: rotate(90deg);
    }
  }

  .details {
    display: flex;
    flex-direction: column;
  }

  .iconButton {
    color: inherit;
    background: transparent;
    border: 0;
    &:hover {
      border: 0;
      background: transparent;
      color: colors.$red;
    }
  }

  .icon {
    cursor: pointer;
    transition: .2s;
    color: inherit;

    &:hover {
      border: 0;
    }
  }

  .loadMoreBtn {
    &:global(.MuiButton-root) {
      align-self: center;
      margin-top: 16px;
    }
  }

  .studentLink {
    display: flex;
    align-items: center;
    gap: 4px;
    text-decoration: none;
    color: colors.$red;
    :visited {
      color: inherit;
    }
  }
}
