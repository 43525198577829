@use "@styles/colors.module.scss";
@use "@styles/borders.module.scss";
@use "@styles/indents.module.scss";

.divider {
  border-color: colors.$grey-light !important;
}
.year {
  padding: indents.$indent-s indents.$indent-xs;
  background: colors.$background;
}

