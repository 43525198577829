@import '@styles/colors.module.scss';
@import '@styles/borders.module.scss';
@import '@styles/shadows.module.scss';

.imgWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  aspect-ratio: 304 / 168;
  gap: 20px;
  background: $background;
  border: 1px solid transparent;
  border-radius: $radius-m;
  color: $grey;
  overflow: hidden;
  cursor: pointer;

  &:global(.loading) {
    gap: 16px;
    cursor: default;
  }

  &:global(.image) {
    cursor: default;

    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
      transition: 0.2s;
    }

    &:hover {
      &:before {
        background-color: rgba($color: $black, $alpha: 0.3);
      }

      & > div {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  &:global(.error) {
    border-color: $red;
  }
}

.input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
}

.indicator {
  width: 200px;
  border-radius: $radius-s;

  &:global(.MuiLinearProgress-root) {
    background-color: $grey-light;
  }

  & :global(.MuiLinearProgress-bar) {
    background-color: $red;
  }
}

.img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.buttons {
  opacity: 0;
  pointer-events: none;
  position: relative;
  z-index: 2;
}

.button {
  &:global(.MuiButtonBase-root) {
    background-color: $white;

    &:hover {
      background-color: $white;
    }
  }
}

.imageInput {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.error {
  color: $red;
}
