@use "@styles/colors.module.scss";
@use "@styles/indents.module.scss";

.closeButton {
  position: absolute !important;
  top: 0;
  right: 0;
  border: none !important;
  padding: indents.$indent-xs !important;
  background-color: transparent !important;

  &:hover {
    border: none !important;
  }
}

.closeIcon {
  color: colors.$dark;
}
