@use "@styles/colors.module.scss";
@use "@styles/indents.module.scss";

.table-option {
  display: flex;
  margin: 5px 2px;
  gap: 5px;
}

.table-option {
  white-space: nowrap;
}

.tableInputBox {
  padding: indents.$indent-s;
  border-color: colors.$grey-light !important;
  margin-top: 4px;
}

.tableInput {
  display: grid;
  grid-template-columns: repeat(6, auto); // настройка количество столбцов в поппере для таблицы
  gap: 2px;
}

.tableUnit {
  width: 16px;
  height: 16px;
  border: 1px solid colors.$grey-light;
  cursor: pointer;
}
