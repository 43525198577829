$md: 720px;
$lg: 1024px;
$xl: 1200px;
$xxl: 1280px;

:export {
  md: $md;
  lg: $lg;
  xl: $xl;
  xxl: $xxl;
}
