@import '@styles/colors.module.scss';
@import '@styles/typography.module.scss';
@import '@styles/font-weights.module.scss';
@import '@styles/indents.module.scss';

.stretchable {
  flex-grow: 1;
}

.hiddenFilterContainer {
  display: flex;
  flex-direction: column;
}

.actionButtons {
  display: flex;
  gap: $indent-s;
  color: $red;
}

.paperFilter {
  display: flex;
  flex-direction: column;
  gap: $indent-s;
  padding: $indent-s;
  width: 100%;
}

.filterResult {
  font-size: $h3FontSize;
  line-height: $h3LineHeight;
  font-weight: $semi-bold;

  .filterTotalCount {
    color: $grey;
  }
}

.filtes {
  display: flex;
  flex-wrap: wrap;
  gap: $indent-s;
}

.emptyFilter {
  gap: 0;
}
