@use '@styles/colors.module.scss';
@import '@styles/index.scss';

.avatar {
  &:global(.MuiAvatar-root) {
    width: 100px;
    height: 100px;
  }
}

.changePhotoBtn {
  position: relative;
  color: $red;
  cursor: pointer;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.7;
  }
}

.changePhotoInput {
  display: none;
}

.submitBtnIcon {
  color: colors.$red;
}

