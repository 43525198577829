/* headings */

h1 {
  font-size: 36px;
  line-height: 36px;
}

h2 {
  font-size: 30px;
  line-height: 35px;
}

h3 {
  font-size: 24px;
  line-height: 29px;
}

@media (min-width: 768px) {
  h2 {
    font-size: 32px;
    line-height: 37px;
  }

  h3 {
    font-size: 30px;
    line-height: 35px;
  }
}

@media (min-width: 1280px) {
  h1 {
    font-size: 72px;
    line-height: 76px;
  }

  h2 {
    font-size: 64px;
    line-height: 64px;
  }

  h3 {
    font-size: 54px;
    line-height: 65px;
  }
}

/* paragraph */
p {
  font-size: 20px;
  line-height: 150%;

  &[data-is-caption='true'] {
    font-size: 16px;
    line-height: 131%;
  }
}

/* lists */
.editor-content ol,
.editor-content ul {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 148%;
  letter-spacing: 0.2px;
  padding-left: 30px;
}

.editor-content ul {
  list-style: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTQgMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wLjY4OTYyMyAxMi44NDU2QzAuMjU4NjA4IDEyLjQwOTQgMCAxMS44ODU5IDAgMTEuMjc1MkMwIDEwLjY2NDQgMC4yNTg2MDggMTAuMTQwOSAwLjY4OTYyMyA5LjcwNDdMMy4zNjE5MSA3TDAuNjg5NjIzIDQuMjk1M0MwLjI1ODYwOCAzLjg1OTA2IDAgMy4zMzU1NyAwIDIuNzI0ODNDMCAyLjExNDA5IDAuMjU4NjA4IDEuNTkwNiAwLjY4OTYyMyAxLjE1NDM2QzEuNTUxNjUgMC4yODE4NzkgMy4wMTcxIDAuMjgxODc5IDMuODc5MTMgMS4xNTQzNkw0LjU0ODU3IDEuODMxOTJDNC42NjYxNyAxLjU4NjU1IDQuODMxNDYgMS4zNjA3IDUuMDM1MzMgMS4xNTQzNkM1Ljg5NzM1IDAuMjgxODc5IDcuMzYyOCAwLjI4MTg3OSA4LjIyNDgzIDEuMTU0MzZMMTQuMDAwNCA3TDguMjI0ODMgMTIuODQ1NkM3LjM2MjggMTMuNzE4MSA1Ljg5NzM1IDEzLjcxODEgNS4wMzUzMyAxMi44NDU2QzQuODMxNDYgMTIuNjM5MyA0LjY2NjE3IDEyLjQxMzUgNC41NDg1NyAxMi4xNjgxTDMuODc5MTMgMTIuODQ1NkMzLjAxNzEgMTMuNzE4MSAxLjU1MTY1IDEzLjcxODEgMC42ODk2MjMgMTIuODQ1NloiIGZpbGw9IiM4MjY5RUYiLz48L3N2Zz4K);
}

.editor-content li {
  margin-bottom: 30px;
}

/* quote */
.quote {
  display: block;
  position: relative;
  padding: 24px;
  padding-left: 48px;
  border-radius: 16px;
  background-color: #f7f6f1;
  backdrop-filter: blur(2px);
  font-size: 18px;
  line-height: 25px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.quote:before,
.quote:after {
  content: none;
}

.quote > img {
  position: absolute;
  top: 28px;
  left: 20px;
}

@media (min-width: 1280px) {
  .quote {
    padding: 32px;
    padding-left: 48px;
    font-size: 20px;
    line-height: 28px;
  }

  .quote > img {
    top: 36px;
  }
}

/* quote with photo*/
.quote-with-photo {
  position: relative;
  padding: 20px 16px;
  border-radius: 15px;
  background-color: #f2dadb;
  color: #352f2f;
}

.quote-with-photo__quote {
  margin: 0;
  font-size: 20px;
  line-height: 28px;
}

.quote-with-photo__author-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 24px;
}

.quote-with-photo__avatar {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  object-fit: cover;
}

.quote-with-photo__author-info-wrapper {
  font-size: 16px;
  line-height: 21px;
}

.quote-with-photo__author-name {
  margin: 0;
  text-decoration: 'underline';
}

.quote-with-photo__author-info {
  margin-top: 4px;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .quote-with-photo {
    padding: 24px;
  }

  .quote-with-photo__quote {
    font-size: 24px;
    line-height: 34px;
  }

  .quote-with-photo__author-wrapper {
    align-items: flex-end;
  }

  .quote-with-photo__avatar {
    width: 80px;
    height: 80px;
  }
}

@media (min-width: 1280px) {
  .quote-with-photo__quote {
    font-size: 32px;
    line-height: 45px;
  }
}

/* article link */
.article-link {
  position: relative;
}

.article-link__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
}

.article-link__photo-wrapper {
  width: 77px;
  min-width: 77px;
  height: 77px;
}

.article-link__photo {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.article-link__link {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.14px;
  text-decoration-line: underline;
  color: #352f2f;
}

@media (min-width: 768px) {
  .article-link__photo-wrapper {
    width: 206px;
    min-width: 206px;
    height: 100px;
  }

  .article-link__link {
    font-size: 20px;
  }
}

@media (min-width: 1280px) {
  .article-link__wrapper {
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    left: calc((100% + 24px) / 8 * 9 - 24px);
    width: calc((100% + 24px) / 8 * 3);
  }
}

/* definition */
.definition-info {
  position: relative;
}

.definition-info__container {
  box-sizing: border-box;
  margin-bottom: 20px;
  padding: 24px;
  border-radius: 24px;
  background-color: #e0d9fb;
}

.definition-info__title {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
}

.definition-info__text {
  font-size: 16px;
  line-height: 20px;
}

@media (min-width: 1280px) {
  .definition-info__container {
    position: absolute;
    left: calc(100% + 24px);
    width: calc((100% + 24px) / 8 * 4 - 24px);
  }

  .definition-info__title {
    display: none;
  }

  .definition-info__text {
    font-size: 20px;
    line-height: 28px;
  }
}

/* image */
.image-left,
.image-right {
  display: inline-flex;
  flex-direction: column;
}

.image-left {
  float: left;
  margin-right: 10px;
}

.image-right {
  float: right;
  margin-left: 10px;
}

.image-center {
  width: 100%;
}

.image-left > img,
.image-right > img,
.image-center > img {
  display: block;
  width: 100%;
  border-radius: 16px;
}

.image-fullWidth > img {
  /* 17px is scroll width */
  width: calc(100vw - 17px);
  margin-left: -16px;
}

.image-author,
.image-description {
  margin-top: 8px;
  font-size: 14px;
  line-height: 19px;
}

@media (min-width: 1280px) {
  .image-left > img,
  .image-right > img,
  .image-center > img {
    border-radius: 24px;
  }

  .image-fullWidth > img {
    margin-left: -36px;
  }

  .image-author,
  .image-description {
    font-size: 18px;
    line-height: 25px;
  }
}

@media (min-width: 1449px) {
  .image-fullWidth > img {
    margin-left: calc((100vw - 1376px) / 2 * -1);
  }
}
