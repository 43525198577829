@use "@styles/colors.module.scss";
@use "@styles/indents.module.scss";

.divider {
  margin: indents.$indent-m 0 !important;
  border-color: colors.$grey-light !important;
}

.row {
  display: flex;
  gap: indents.$indent-xs;
  height: 24px;
  align-items: center;
}

.rowTitle {
  width: 200px;
  flex-shrink: 0;
  color: colors.$black;
}
