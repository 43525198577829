@import '@styles/index.scss';

.textField {
  & :global(.MuiInputBase-root) {
    padding-right: 8px;
  }

  & :global(.MuiInputBase-input) {
    height: auto;
    font-size: $bodyFontSize;
    line-height: $bodyLineHeight;
  }

  &:global(.fullWidth) {
    width: 100%;
  }
}

.openPickerButton:global(.MuiIconButton-root) {
  border-radius: 50%;
  border: 0;

  &:hover {
    border: 0;
  }
}
