@use '@styles/colors.module.scss';

.title {
  white-space: normal;
}

.iconButton {
  &:global(.MuiIconButton-root) {
    padding: 4px;
    border: 0;
    color: colors.$black;

    &:hover {
      border: 0;
      color: colors.$red;
    }
  }
}
