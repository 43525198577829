@use '@styles/colors.module.scss';
@use '@styles/borders.module.scss';
@use '@styles/shadows.module.scss';
@use '@styles/indents.module.scss';

.formWrapper {
  position: relative;
  padding: 20px;
  border-radius: borders.$radius-m;
  box-shadow: shadows.$page-content !important;
  background-color: colors.$white;
  margin-bottom: indents.$indent-m;
}

.submitBtnWrapper {
  height: 80px;
  padding: 20px;
  border-radius: 12px 12px 0 0;
  background-color: colors.$black;
  position: fixed;
  z-index: 100;
  bottom: 0;
}

.submitBtnIcon {
  color: colors.$red;
}

.loadingIndicator {
  &:global(.MuiLinearProgress-root) {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-radius: 0 0 8px 8px;
  }
}
