@use "@styles/colors.module.scss";
@use "@styles/indents.module.scss";
@use "@styles/borders.module.scss";

.root {
  width: auto;

  & > div {
    height: 38px;
    padding-right: 32px;

    background-color: colors.$background;

    border-width: 0 0 1px;
    border-radius: borders.$radius-s borders.$radius-s 0 0;
  }

  .arrow {
    color: colors.$black;
    right: 4px;
  }
}
