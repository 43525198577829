@import '@styles/colors.module.scss';

.root {
  position: relative;

  .table {
    width: 100%;

    &.tableLayoutFixed {
      table-layout: fixed;
    }
  }
}

.body {
  &:global(.loading) {
    opacity: 0.4;
  }
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;

  &:global(.loading) {
    opacity: 0.4;
  }
}

.spinnerWrapper {
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:global(.loading) {
    display: flex;
  }
}

.spinner {
  & svg {
    color: $red;
  }
}

.row {
  &:global(.MuiTableRow-root.loadingRow) {
    position: relative;
    pointer-events: none;
    opacity: 0.7;
  }
}

.cell {
  &:global(.MuiTableCell-root.ellipsisCell) {
    text-overflow: ellipsis;
    word-break: keep-all;
  }
}

.loadingIndicator {
  &:global(.MuiLinearProgress-root) {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
  }
}
