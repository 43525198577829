@import '@styles/index.scss';

.drawer {
  &:global(.MuiDrawer-root) {
    flex-shrink: 0;
    white-space: nowrap;
    box-sizing: border-box;
  }

  & :global(.MuiDrawer-paper) {
    width: $drawer-width;
    overflow: initial;
    background-color: $black;
    border-radius: 0 20px 20px 0;
    border: 0;
    transition: width 0.2s;
  }

  &:global(.open .MuiDrawer-paper) {
    width: $drawer-width-open;
  }
}

.drawerHeader {
  min-height: 120px;
  overflow: hidden;
}

.logoWrapper {
  display: block;
  margin: 30px 16px;
  overflow: hidden;
  transition: 0.2s;

  &:global(.open) {
    margin: 30px;
  }
}

.chapters {
  overflow-x: hidden;
  overflow-y: auto;

  @include scrollbar($black, $grey);
}

.chapterTitleWrapper {
  &:global(.MuiListItemButton-root) {
    padding: $indent-xs $indent-xs $indent-xs 18px;
    border-top: 1px solid $dark;
    border-bottom: 1px solid $dark;
    transition: 0.2s;
  }

  &:global(:hover .MuiTypography-root) {
    color: $red;
  }

  &:hover svg:first-child {
    color: $red;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    height: 100%;
    background-color: transparent;
    transition: background-color 0.2s;
  }

  &:global(.active .MuiTypography-root) {
    color: $red;
  }

  &:global(.active svg:first-child) {
    color: $red;
  }

  &:global(.active:before) {
    background-color: $red;
  }

  &:global(.MuiListItemButton-root.Mui-disabled) {
    opacity: 1;
  }

  &:global(.MuiListItemButton-root.Mui-disabled .MuiTypography-root) {
    color: $grey;
  }

  &:global(.MuiListItemButton-root.Mui-disabled svg:first-child) {
    color: grey;
  }

  &:global(.openSidebar) {
    padding-left: $indent-xs;
  }
}

.chapterTitle {
  color: $almost-white;
  transition: color 0.2s;
}

.chapterTitleIcon {
  margin-right: 18px;

  &:global(.MuiSvgIcon-root) {
    transition: 0.2s;
  }

  &:global(.openSidebar) {
    margin-right: 6px;
  }
}

.chevronIcon {
  margin-left: auto;
  transform: rotate(-90deg);

  &:global(.MuiSvgIcon-root) {
    transition: transform 0.2s;
  }

  &:global(.open) {
    transform: rotate(0deg);
  }
}

.listItem {
  &:global(.MuiListItem-root) {
    padding: $indent-xs $indent-xs $indent-xs 42px;
    background-color: $dark;
    margin-bottom: 0;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    height: 100%;
    background-color: transparent;
  }

  &:global(.active:before) {
    background-color: $red;
  }
}

.link {
  color: $almost-white;
  font-size: $bodyFontSize;
  line-height: $bodyLineHeight;
  font-weight: $regular;
  text-decoration: none;
  transition: 0.2s;

  &:hover {
    color: $red;
  }

  &:global(.active) {
    color: $red;
    font-weight: $semi-bold;
  }
}

.user {
  overflow: hidden;
  padding: $indent-xs 14px;
  margin-top: auto;
  border-top: 1px solid $grey;
  transition: 0.2s;

  &:global(.open) {
    padding: 12px;
  }
}

.logoutBtn {
  &:global(.MuiIconButton-root) {
    border-radius: 50%;
    background: transparent;
    border: 0;

    &:hover {
      background: transparent;
      border: 0;
    }
  }
}

.toggleBtn {
  &:global(.MuiIconButton-root) {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 0;
    position: absolute;
    top: 50%;
    right: -28px;
    transform: translateY(-50%);
    background-color: $black;
    z-index: -1;

    &:hover {
      background-color: $black;
      border: 0;
    }
  }
}

.iconToggleBtn {
  &:global(.MuiSvgIcon-root) {
    width: 28px;
    height: 28px;
    margin-left: 20px;
  }
}

.list{
  .listItem{
    padding-right: 42px;

    &:first-child{
      border-top-right-radius: 8px !important;
      border-top-left-radius: 8px !important;
    }
  
    &:last-child{
      border-bottom-right-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
    }
  }
}