@use "@styles/colors.module.scss";
@use "@styles/indents.module.scss";

.root {
  position: sticky;
  top: 0;
  align-items: center;
  padding: 20px 20px indents.$indent-m;
  background-color: colors.$white;
  z-index: 1;
  border-bottom: 2px solid colors.$black;

  .activeTool {
    background-color: colors.$grey-light;
  }
}
