$indent-xxxs: 6px;
$indent-xxs: 8px;
$indent-xs: 12px;
$indent-s: 16px;
$indent-m: 24px;
$indent-l: 32px;
$indent-xl: 48px;

:export {
  xxxs: $indent-xxxs;
  xxs: $indent-xxs;
  xs: $indent-xs;
  s: $indent-s;
  m: $indent-m;
  l: $indent-l;
  xl: $indent-xl;
}
