@import '@styles/index.scss';

.container {
  height: 100%;
  background-color: $background;
}

.title {
  &:global(.MuiTypography-root) {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 100px;
    line-height: 121px;
    font-weight: $bold;
  }
}

.logoWrapper {
  display: flex;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: $black;

  & svg {
    width: 100%;
    color: $white;

    & mask:first-child path {
      fill: transparent;
    }
  }
}

.btn {
  &:global(.MuiButton-root) {
    margin-top: 20px;
    padding: 8px 40px;
  }
}
