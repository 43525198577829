@use '@styles/colors.module.scss';

.title {
  white-space: normal;
}

.iconButton {
  &:global(.MuiIconButton-root) {
    padding: 0;
    border: 0;
    color: colors.$black;
    background: transparent;

    &:hover {
      border: 0;
      background: transparent;
    }

    &:disabled {
      opacity: 0.4;
      border: 0;
    }
  }
}
