@import '@styles/colors.module.scss';
@import '@styles/typography.module.scss';

.raw-data {
  font-family: 'Inter', sans-serif;
  font-size: $bodyFontSize;
  line-height: $bodyLineHeight;
  margin: 0;
}

.loader {
  > svg {
    color: $red;
  }
}
