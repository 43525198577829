@import '@styles/index.scss';

.paper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
}

.loadMoreBtn {
  &:global(.MuiButton-root) {
    align-self: center;
  }
}

.textRBD {
  font-weight: 600 !important;
  color: $grey;
}

.textGrey {
  color: $grey;
}

.createPartnerBtn {
  margin-left: 16px;
}

.refreshBtnIcon {
  &:global(.spin) {
    animation: 2s spin infinite;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
