@import '@styles/index.scss';

.balloon-hint {
  padding: 12px;
  font-family: Inter, sans-serif;

  &__name {
    margin: 0;
    font-size: $bodyBoldFontSize;
    line-height: $bodyLineHeight;
  }

  &__text {
    margin-top: 4px;
    margin-bottom: 0;
    font-size: $captionFontSize;
    line-height: $captionLineHeight;
  }

  &__operating-mode {
    margin-top: 12px;
  }
}

.ymaps-2-1-79-hint {
  border-radius: 8px !important;
  background: $white;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);

  &__text {
    border-radius: 8px !important;
  }
}
