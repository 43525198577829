@use '@styles/colors.module.scss';
@use '@styles/indents.module.scss';

.paper {
  display: flex;
  flex-direction: column;
  padding: 20px;

  .iconButton {
    color: inherit;
    background: transparent;
    border: 0;

    &:hover {
      border: 0;
      background: transparent;
      color: colors.$red;
    }

    &:disabled {
      border: 0;

      .icon {
        color: colors.$grey;
      }
    }

    .icon {
      cursor: pointer;
      color: inherit;

      &:hover {
        border: 0;
      }
    }
  }

  .loadMoreBtn {
    &:global(.MuiButton-root) {
      align-self: center;
      margin: 16px 0;
    }
  }

  .totalRow {
    background-color: colors.$almost-white;

    &__name {
      padding: indents.$indent-s indents.$indent-xs;
      flex: 0 0 740px;
    }
    &__studentCount {
      padding: indents.$indent-s indents.$indent-xs;
      flex: 0 0 160px;
    }
    &__sum {
      padding: indents.$indent-s indents.$indent-xs;
      flex: 1
    }
  }
}
