@use "@styles/colors.module.scss";
@use "@styles/indents.module.scss";

.root {
  .field, .buttons {
    width: 278px;
  }

  .fieldLabel {
    flex: 0 0 32px;
    margin-right: indents.$indent-xs;
  }

  .keyHole {
    margin-left: 4px;
  }

  .buttons {
    margin-left: 44px;
  }

  .button {
    flex: 1;
  }

  .description {
    margin-top: indents.$indent-xxs;
  }

  .descriptionEmail {
    color: colors.$red;
  }

  .loadingIndicator {
    &:global(.MuiLinearProgress-root) {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
    }
  }
}
