@use '@styles/colors.module.scss';
@use '@styles/borders.module.scss';
@use '@styles/shadows.module.scss';
@use '@styles/indents.module.scss';
@import '@styles/index.scss';

.formWrapper {
  position: relative;
  padding: 20px;
  border-radius: borders.$radius-m;
  box-shadow: shadows.$page-content !important;
  background-color: colors.$white;
  margin-bottom: indents.$indent-m;
}

.datePicker {
  width: 100%;

  @include breakpoint(desktop) {
    width: auto;
  }
}

.datesDelimiter {
  display: none;

  @include breakpoint(desktop) {
    display: block;
    width: 8px;
    height: 2px;
    background: colors.$grey;
  }
}

.typeSelect {
  @include breakpoint(desktop) {
    max-width: 424px;
  }
}

.imageInputsWrapper {
  width: 100%;

  @include breakpoint(desktop) {
    flex-grow: 1;
    width: auto;
  }
}

.submitBtnWrapper {
  height: 80px;
  padding: 20px;
  border-radius: 12px 12px 0 0;
  background-color: colors.$black;
  position: fixed;
  z-index: 1;
  bottom: 0;
}

.submitBtnIcon {
  color: colors.$red;
}

.loadingIndicator {
  &:global(.MuiLinearProgress-root) {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-radius: 0 0 8px 8px;
  }
}

.accordionStyles {
  width: 100%;
  border: 1px solid $grey-light;
  border-radius: 8px !important;

  &::before {
    display: none;
  }
}

.divider {
  margin: indents.$indent-m 0 !important;
  border-color: colors.$grey-light !important;
}

.programmDivider {
  margin: indents.$indent-xxs 0 !important;
  border-color: colors.$grey-light !important;
}

.row {
  display: flex;
  gap: indents.$indent-xs;
  word-break: break-word;

  &:last-child {
    margin-bottom: indents.$indent-m;
  }
}

.rowTitle {
  width: 200px;
  flex-shrink: 0;
  color: colors.$black;
}

.stackStyles {
  background-color: $background;
  padding: 20px;
  border-radius: 8px;
}

.textBold {
  font-weight: 600;
  color: $grey;
}

.notAnchor {
  max-width: 292px;
  padding: 22px 16px;
  border: 1px solid $grey-light;
  border-radius: 19px;
}

.discount {
  background: #8269ef;
  border-radius: 19px;
  padding: 3px 6px;
  color: $white;
  line-height: 15px;
}

.description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.anchor {
  clip-path: polygon(
    0% 15%,
    14% 15%,
    20% 0,
    40% 0,
    100% 0,
    100% 15%,
    100% 85%,
    100% 100%,
    85% 100%,
    15% 100%,
    0 100%,
    0% 85%
  );

  background-color: #8269ef;
  height: 195px;
  width: 292px;
  border-radius: 60px;
}

.logo {
  width: 67px;
  height: 64px;
  border-radius: 19px;
  object-fit: contain;
}

.emptyCard {
  width: 292px;
  height: 195px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0px 0px 0px #cbdafc;
  color: $grey;
}
