@use "@styles/colors.module.scss";
@use "@styles/borders.module.scss";
@use "@styles/shadows.module.scss";

.tab {
  border-width: 0 !important;
  overflow: hidden !important;

  &:global(.Mui-selected) {
    background-color: colors.$white;
    z-index: 1;
  }
}

.tabPanel {
  margin-top: -1px;
  padding: 20px;
  background-color: colors.$white;
  border-radius: borders.$radius-m;
  box-shadow: shadows.$page-content;
}
