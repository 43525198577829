@use '@styles/colors.module.scss';
@use '@styles/borders.module.scss';
@use '@styles/shadows.module.scss';
@use '@styles/indents.module.scss';
@import '@styles/index.scss';

.formWrapper {
  position: relative;
  padding: 20px;
  border-radius: borders.$radius-m;
  box-shadow: shadows.$page-content !important;
  background-color: colors.$white;
  margin-bottom: indents.$indent-m;
}

.datePicker {
  width: 100%;

  @include breakpoint(desktop) {
    width: auto;
  }
}

.datesDelimiter {
  display: none;

  @include breakpoint(desktop) {
    display: block;
    width: 8px;
    height: 2px;
    background: colors.$grey;
  }
}

.typeSelect {
  @include breakpoint(desktop) {
    max-width: 424px;
  }
}

.imageInputsWrapper {
  width: 100%;

  @include breakpoint(desktop) {
    flex-grow: 1;
    width: auto;
  }
}

.submitBtnWrapper {
  height: 80px;
  padding: 20px;
  border-radius: 12px 12px 0 0;
  background-color: colors.$black;
  position: fixed;
  z-index: 100;
  bottom: 0;
}

.submitBtnIcon {
  color: colors.$red;
}

.loadingIndicator {
  &:global(.MuiLinearProgress-root) {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-radius: 0 0 8px 8px;
  }
}
