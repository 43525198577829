@use "@styles/colors.module.scss";
@use "@styles/borders.module.scss";
@use "@styles/shadows.module.scss";
@use "@styles/indents.module.scss";

.tab {
  &:global(.Mui-selected) {
    border-bottom-color: colors.$white !important;
    z-index: 1;
  }
}

.tabPanel {
  margin-top: -1px;
}
