@use "@styles/colors.module.scss";
@use "@styles/borders.module.scss";
@use "@styles/indents.module.scss";

.divider {
  margin: indents.$indent-m 0 !important;
  border-color: colors.$grey-light !important;
}

.right {
  position: relative;
  padding: 20px;
  border: 1px solid colors.$grey;
  border-radius: borders.$radius-m;
}

.rightTitle {
  text-transform: uppercase;
  margin-bottom: indents.$indent-m !important;
}

.rightIcon {
  position: absolute;
  top: 20px;
  right: 20px;
}

.row {
  display: flex;
  gap: indents.$indent-xs;
  &:last-child {
    margin-bottom: indents.$indent-m;
  }
}

.rowTitle {
  width: 200px;
  flex-shrink: 0;
  color: colors.$black;
}

.editButton {
  display: flex;
  margin-left: auto;
}
