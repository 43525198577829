@use '@styles/colors.module.scss';
@use '@styles/indents.module.scss';

.accordion {
  & :global(.MuiAccordionSummary-expandIconWrapper.Mui-expanded)  {
    transform: rotate(90deg);
  }
}

.downloadButtons {
  display: flex;
  gap: 4px;

  .downloadButton {
    gap: indents.$indent-xxxs;

    &Loader {
      color: colors.$grey;
    }
  }
}

