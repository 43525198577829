@import '@styles/index.scss';

.root {
  width: 100%;
}

.mapWrapper {
  aspect-ratio: 792 / 365;
  border-radius: 20px;
  overflow: hidden;
}
