@import '@styles/colors.module.scss';

.paper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
}

.loadMoreBtn {
  &:global(.MuiButton-root) {
    align-self: center;
  }
}

.iconButton {
  &:global(.MuiIconButton-root) {
    padding: 4px;
    border: 0;
    color: $black;

    &:hover {
      border: 0;
      color: $red;
    }
  }
}

.date {
  white-space: nowrap;
}
