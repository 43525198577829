@use '@styles/colors.module.scss';
@use '@styles/indents.module.scss';

.root {
  height: 100%;
  background-color: colors.$background;

  .logo {
    position: fixed;
    top: 40px;
    left: 40px;

    svg {
      display: block;
    }
  }

  .content {
    position: relative;
    overflow: hidden;
    padding: indents.$indent-l 40px;
    border-color: colors.$grey-light;
  }
}
