@import '@styles/colors.module.scss';

.key-quote-card {
  position: relative;
  padding: 10px 100px 10px 10px;
  border: 1px solid $grey-light;
  user-focus: none;
  user-select: none;
  &__actions {
    position: absolute;
    background-color: $white;
    right: 10px;
    top: 5px;
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 5px;
    button {
      padding: 2px 5px;
      background-color: transparent;
      border: 1px solid $grey;
      cursor: pointer;
      &:hover {
        background-color: $almost-white;
      }
    }
  }
  &__info {
    position: relative;
  }
}
