.scrollTabs {
  position: relative;

  &:global(.MuiTabs-scrollButtons) {
    background-color: green;
    z-index: 1;
  }

  div:last-child {
    right: 0!important;
  }
}