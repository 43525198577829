$h1FontSize: 24px;
$h1LineHeight: 32px;

$h2FontSize: 18px;
$h2LineHeight: 22px;

$h3FontSize: 16px;
$h3LineHeight: 20px;

$bodyBoldFontSize: 14px;
$bodyBoldLineHeight: 18px;

$bodyFontSize: 14px;
$bodyLineHeight: 24px;

$captionFontSize: 12px;
$captionLineHeight: 16px;

:export {
  h1FontSize: $h1FontSize;
  h1LineHeight: $h1LineHeight;

  h2FontSize: $h2FontSize;
  h2LineHeight: $h2LineHeight;

  h3FontSize: $h3FontSize;
  h3LineHeight: $h3LineHeight;

  bodyBoldFontSize: $bodyBoldFontSize;
  bodyBoldLineHeight: $bodyBoldLineHeight;

  bodyFontSize: $bodyFontSize;
  bodyLineHeight: $bodyLineHeight;

  captionFontSize: $captionFontSize;
  captionLineHeight: $captionLineHeight;
}
