@import '@styles/layout.module.scss';
@import '@styles/colors.module.scss';

.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  position: absolute;

  .bg {
    flex-grow: 1;
    height: 100%;

    padding: 30px 60px 80px calc($drawer-width + 44px);
    background-color: $background;
    transition: padding-left 0.2s;

    &:global(.open) {
      padding-left: calc($drawer-width-open + 44px);
    }
  }
}
