@use '@styles/colors.module.scss';
@import '@styles/index.scss';

.wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 0 28px 28px 0;
  overflow: hidden;
  cursor: pointer;
  width: 292px;
}

.header {
  position: relative;
  max-height: 124px;
}

.before {
  position: absolute;
  width: 100%;
  height: 20px;
  bottom: 0;
  left: 0;
  &.purple {
    background-color: $purple50;
  }
  &.red {
    background-color: $red50;
  }
}

.after {
  position: absolute;
  width: 114px;
  height: 100%;
  top: 0;
  left: -38px;

  border-bottom-right-radius: 32px;
  &.purple {
    background-color: $white;
  }
  &.red {
    background-color: $white;
  }
}

.decoration {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: contain;
}

.description {
  flex-grow: 1;
  position: relative;
  border-radius: 0px 0px 28px 20px;
  border-top-left-radius: 16px;
  padding: 24px;
  &.purple {
    background-color: $purple50;
  }
  &.red {
    background-color: $red50;
  }
}

.decorationWrap {
  position: absolute;
  z-index: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  bottom: -3px;
  right: -3px;
  width: max(126px, 35%);

  svg {
    height: 290px;
  }
}

.descriptionText {
  position: relative;
  z-index: 0;
  color: #352f2f;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.logoBlock {
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 64px;
  z-index: 5;
  &.purple {
    color: #8269ef;
  }
  &.red {
    color: $red;
  }

  svg {
    width: 80px;
    height: 64px;
  }
}

.logo {
  position: absolute;
  top: 12px;
  left: 16px;
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.titleWrap {
  display: flex;
  flex-direction: column;
  margin-left: 80px;
  margin-bottom: -1px;
  position: relative;
  gap: 12px;
  padding: 16px;
  padding-bottom: 4px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    transform: skew(-18deg);
    border-top-left-radius: 16px;
    .purple & {
      background-color: $purple50;
    }
    .red & {
      background-color: $red50;
    }
  }

  &:after {
    content: '';
    position: absolute;
    width: 50%;
    height: 100%;
    z-index: 0;
    top: 0;
    left: 50%;
    .purple & {
      background-color: $purple50;
    }
    .red & {
      background-color: $red50;
    }
  }
}

.title {
  position: relative;
  z-index: 1;
  font-size: 20px;
  font-family: var(--font-giga-sans);
  font-style: normal;
  font-weight: 700;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.type {
  position: relative;
  z-index: 1;
  min-height: 24px;
  font-family: var(--font-inter);
  font-style: normal;
  font-weight: 500;
  color: rgba(34, 34, 34, 0.6);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
