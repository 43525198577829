@use '@styles/borders.module.scss';
@use '@styles/shadows.module.scss';
@use '@styles/indents.module.scss';
@import '@styles/index.scss';

.root {
  .filters {
    padding: 24px;
    margin:0 -24px;
    background: $background;
    position: sticky;
    top: 0;
    z-index: 50;
  }

  .table {
    overflow-x: initial;

    & :global(.MuiTablePagination-root) {
      position: sticky;
      background: $white;
      bottom: 0;
      border-radius: borders.$radius-m;
    }
  }
}
