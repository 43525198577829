@use "@styles/colors.module.scss";
@use "@styles/borders.module.scss";
@use "@styles/indents.module.scss";
@use "@styles/font-weights.module.scss";

.textCount {
  min-width: 34px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 indents.$indent-xxs;
  background-color: colors.$success;
  border-radius: borders.$radius-s;
  color: colors.$white;
  transition: background-color 0.124s ease-in;

  &.error {
    background-color: colors.$error;
  }
}
