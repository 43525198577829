@import '@styles/index.scss';

.actions {
  position: absolute;
  right: 10px;
  top: 5px;
  z-index: 0;
  display: flex;
  align-items: center;
  gap: 5px;
  button {
    padding: 2px 5px;
    background-color: $white;
    border: 1px solid $grey;
    cursor: pointer;
    &:hover {
      background-color: $almost-white;
    }
  }
}
