@import '@styles/colors.module.scss';

.wrapper {
  padding: 2px 0;
}

.skeleton {
  &:global(.MuiSkeleton-root) {
    min-width: 32px;
    background-color: $grey;
    transition: margin-right 0.2s;

    &:global(.short) {
      margin-right: 10px;
    }
  }

  &:global(.text) {
    flex-grow: 1;
    font-size: 1rem;
  }
}

.linkWrapper {
  max-width: calc(100% - 44px);
}

.link {
  text-decoration: none;
  color: $white;
}

.short {
  color: $black;
  transition: color 0.2s;
}
