@use '@styles/colors.module.scss';
@use '@styles/borders.module.scss';
@use '@styles/shadows.module.scss';
@use '@styles/indents.module.scss';

@import '@styles/index.scss';

.formWrapper {
  position: relative;
  border-radius: borders.$radius-m;
  background-color: colors.$white;
  margin-bottom: indents.$indent-m;
}

.submitBtnWrapper {
  height: 80px;
  padding: 20px;
  border-radius: 12px 12px 0 0;
  background-color: colors.$black;
  position: fixed;
  z-index: 100;
  bottom: 0;
}

.submitBtnIcon {
  color: colors.$red;
}

.fieldWidth {
  width: 50%;
}

.avatar {
  &:global(.MuiAvatar-root) {
    width: 100px;
    height: 100px;
  }
}
