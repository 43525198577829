@use '@styles/colors.module.scss';
@use '@styles/borders.module.scss';

@import '@styles/index.scss';

.root {
  border: 1px solid colors.$grey-light;
  border-radius: 0 0 borders.$radius-m borders.$radius-m;
  padding: 20px;
}

.imageInputsWrapper {
  width: 100%;

  @include breakpoint(desktop) {
    flex-grow: 1;
    width: auto;
  }
}

.btn {
  align-self: flex-start;
}
