$radius-xs: 2px;
$radius-s: 4px;
$radius-m: 8px;
$radius-l: 16px;

:export {
  radiusXs: $radius-xs;
  radiusS: $radius-s;
  radiusM: $radius-m;
  radiusL: $radius-l;
}
