@import '@styles/colors.module.scss';
@import '@styles/typography.module.scss';
@import '@styles/font-weights.module.scss';
@import '@styles/indents.module.scss';

.subTitle {
  font-size: $bodyFontSize;
  line-height: $bodyLineHeight;
  font-weight: $regular;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.periodTitle {
  font-size: $bodyFontSize;
  line-height: $bodyLineHeight;
  font-weight: $semi-bold;
}
