@use '@styles/colors.module.scss';
@use '@styles/borders.module.scss';
@use '@styles/indents.module.scss';
@use '@styles/font-weights.module.scss';
@use '@styles/screen-sizes.module.scss';
@import '@styles/mixins.scss';

.iframe {
  border: 0;
}

.modal {
  padding: 0 !important;
}

.buttons {
  display: inline-flex;
  width: 112px;
  height: 56px;
  background-color: colors.$background;

  @include breakpoint(desktop) {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .button {
    width: 56px;
    min-width: 56px;
    border-radius: borders.$radius-s;

    .buttonIcon {
      color: colors.$black;
      transition: color 0.14s ease-in;
    }

    &:hover {
      background-color: colors.$grey-light;

      .buttonIcon {
        color: colors.$red;
      }
    }
  }
}
