@import '@styles/colors.module.scss';
@import '@styles/borders.module.scss';
@import '@styles/shadows.module.scss';

.textFieldWrapper {
  width: 100%;
}

.textField {
  width: 100%;
}
