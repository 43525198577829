@use '@styles/typography.module.scss';
@use '@styles/font-weights.module.scss';
@use '@styles/colors.module.scss';
@use '@styles/borders.module.scss';
@use '@styles/shadows.module.scss';
@use '@styles/indents.module.scss';

.root {
  position: relative;
  background-color: colors.$background;
  border-radius: borders.$radius-m;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 670 / 370;

  .buttonIcon {
    color: colors.$white;
  }

  .playButton {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 56px;
    height: 56px;
    background-color: colors.$red;
    border: 3px solid colors.$white;
    border-radius: 50%;
    box-shadow: shadows.$button-shadow;
    transform: translate(-50%, -50%);
    transition-property: background-color, opacity;
    transition: 0.16s ease-in;
    z-index: 15;
    opacity: 1;
  }
  .playButtonDisabled {
    background-color: colors.$grey-light;
    cursor: default;
  }
  .playButtonPlaying {
    z-index: 5;
    opacity: 0;
  }

  .controls {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    padding: 0 indents.$indent-xs;
    color: colors.$white;
    background-color: colors.$black;
    transition-property: background-color, transform;
    transition: 0.16s ease-in;
    z-index: 20;

    .controlsButton {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      &:first-child {
        margin-right: 4px;
      }
    }
    .timeline {
      flex: 1;
      height: indents.$indent-xxs;
      overflow: hidden;
      margin: 0 40px;
      border-radius: borders.$radius-m;
      background-color: colors.$grey-light;
      transition: background-color 0.124s ease-in;
      cursor: pointer;

      .progress {
        height: 100%;
        border-radius: borders.$radius-m;
        background-color: colors.$red;
        transition: width 0.1s ease-in;
      }
    }
    .time {
      min-width: 40px;
    }

    &.playing {
      transform: translateY(30px);
    }
  }
  .controlsDisabled {
    background-color: colors.$grey-light;

    .timeline {
      background-color: colors.$white;
      cursor: default;
    }
  }

  .backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    z-index: 10;
  }
  .backdropDisabled {
    cursor: default;
  }

  &.playing {
    &:hover {
      .controls {
        transform: translateY(0);
      }
    }
  }
}
