@import '@styles/colors.module.scss';
@import '@styles/typography.module.scss';
@import '@styles/font-weights.module.scss';

.paper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
}

.loadMoreBtn {
  &:global(.MuiButton-root) {
    align-self: center;
  }
}

.searchResult {
  font-size: $captionFontSize;
  line-height: $captionLineHeight;
  color: $grey;
}

.totalCount{
  font-weight: $semi-bold;
  color: $grey;
}