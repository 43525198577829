@use '@styles/colors.module.scss';
@use '@styles/indents.module.scss';
@use '@styles/borders.module.scss';
@import '@styles/index.scss';

.root {
  table,
  th,
  td {
    border: 1px solid colors.$grey;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    min-height: 30px;
    padding: 0 5px;
  }

  background-color: colors.$white;

  .border {
    position: relative;
    border: 1px solid colors.$grey-light;
    border-radius: 0 0 borders.$radius-s borders.$radius-s;
  }

  .viewportBox {
    padding: 20px;
  }

  .viewport {
    max-width: 780px;
    // min-height: 600px !important;
    margin: 0 auto;

    &:after {
      content: '';
      display: block;
      height: 0;
      clear: both;
    }

    // overriding editor content common styles

    & :global(.definition-info__container) {
      @include breakpoint(desktopExtraLarge) {
        position: static;
        width: 100%;
      }
    }

    & :global(.definition-info__title) {
      @include breakpoint(desktopExtraLarge) {
        display: block;
      }
    }

    & :global(.article-link__wrapper) {
      @include breakpoint(desktopExtraLarge) {
        position: static;
        flex-direction: row;
        align-items: center;
        width: 100%;
      }
    }

    & :global(.image-card) {
      position: relative;
      user-select: none;
      border-radius: 16px;

      &:global(.image-fullWidth) {
        & :global(.image-card__image > img) {
          border-radius: 0;
        }
      }

      & :global(.image-left),
      & :global(.image-right),
      & :global(.image-center) {
        flex-direction: column;
      }

      & :global(.image-left),
      & :global(.image-right) {
        display: inline-flex;
      }

      & :global(.image-center) {
        display: flex;
        width: 100%;
      }

      & :global(.image-left) {
        float: left;
      }

      & :global(.image-right) {
        float: right;
      }

      & :global(.image-card__image-wrapper) {
        width: 100%;
        max-width: 780px;
      }

      & :global(.image-card__image > img) {
        width: 100%;
        height: auto;
        border-radius: 16px;
      }

      & :global(.image-card__actions) {
        position: absolute;
        background-color: $white;
        right: 10px;
        top: 5px;
        z-index: 0;
        display: flex;
        align-items: center;
        button {
          padding: 2px 5px;
          background-color: transparent;
          border: 1px solid $grey;
          cursor: pointer;
          &:hover {
            background-color: $almost-white;
          }
        }
      }
    }
  }

  .characterCount {
    position: absolute;
    bottom: indents.$indent-xs;
    right: 20px;
    color: colors.$grey;
  }
}
