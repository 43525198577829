@use "@styles/colors.module.scss";
@use "@styles/borders.module.scss";
@use "@styles/indents.module.scss";

.tableContainer {
  position: relative;

  .table {
    width: 100%;
    table-layout: fixed;

    .row, .textRow {
      &:hover {
        background-color: transparent;
      }
    }

    .textRow td {
      padding-top: 0;
    }

    .withSubRow td {
      border-bottom: none
    }

    .canClick {
      cursor: pointer;

      &:hover {
        background-color: colors.$background;

        & + .textRow {
          background-color: colors.$background;
        }
      }

      &:has(+ .canClickTextRow:hover) {
        background-color: colors.$background;
      }
    }

    .canClickTextRow {
      cursor: pointer;

      &:hover {
        background-color: colors.$background;
      }
    }
  }
}

.noData {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
}
