$input-shadow: 0px 0px 0px #cbdafc, 0px 0px 0px #cbdafc;
$card-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
$button-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
$alert-shadow: 0px -2px 16px rgba(0, 0, 0, 0.13);
$page-content: 14px 17px 40px 4px rgba(138, 182, 209, 0.08);

:export {
  input: $input-shadow;
  card: $card-shadow;
  button: $button-shadow;
  alert: $alert-shadow;
  pageContent: $page-content;
}
